import Image from 'next/image';
import Link from 'next/link';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import styles from '../styles/components/header.module.scss';
import LoginBox from './login-box';
import { useRouter } from 'next/router';
import Icon from './base/icon';
import { UserContext } from './user-provider';
import { AGENCY_CATEGORY_ID_FOR_PST, ROLES } from '../shared/constants';
import { signOut, userUseSocialLogin } from '../services/cognito';
import classNames from 'classnames';
import mobileMenuStyles from '../styles/molecules/mobile-menu.module.scss';
import LanguageSelection from './base/language-selection';
import { BigSearchBox } from './big-search-box';
import { apiRestGet } from '../services/request';
import NotificationButtonNav from './base/notification-button-nav';
import DropDown from './base/dropdown/dropdown';
import DropdownMenu from './base/dropdown/dropdown-menu';
import SignOutButton from './base/sign-out-button';
import { useTranslations } from 'next-intl';

type HeaderProps = {
  pageType: number;
};

const Header: FunctionComponent<HeaderProps> = ({ pageType }) => {
  const t = useTranslations('Header');
  const router = useRouter();
  const windowWidthForMobile: number = 800;

  const { user } = useContext(UserContext);

  const [windowWidth, setWindowWidth] = useState();
  const [mobileMenu, setMobileMenu] = useState([]);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [profilePath, setProfilePath] = useState('/');
  const [_, setPoints] = useState(0);
  const [__, setTouristLogged] = useState(false);

  const signOutUser = async () => {
    await signOut();
    setShowLogoutPopup(false);
    router.push('/').then();
  };

  const fillTheWidthOfTheWindow = () => {
    // @ts-ignore
    setWindowWidth(window.innerWidth);
    if (
      window.innerWidth <= windowWidthForMobile &&
      user?.attributes != undefined &&
      getRole() === ROLES.TOURIST
    ) {
      setMobileMenu(navLinks[ROLES.TOURIST].concat(dropdownOption));
    } else {
      setMobileMenu(navLinks[ROLES.NO_LOGGED]);
    }
  };

  const showLoginBoxOnMobile = () => {
    setShowMobileMenu(false);
    setShowLoginPopup(true);
  };

  useEffect(() => {
    const handleRouteChange = () => setShowMobileMenu(false);
    router.events.on('beforeHistoryChange', () => handleRouteChange);
    return () => {
      router.events.off('beforeHistoryChange', handleRouteChange);
    };
  }, []);

  const getRole = () => {
    if (user?.attributes != undefined) {
      if (userUseSocialLogin(user.username)) {
        return ROLES.TOURIST;
      }
      return Number(user?.attributes['custom:role_id']);
    }
    return '';
  };
  const setPointsTourist = async () => {
    let infoPoints = await apiRestGet(
      'puntos/activos/' + user.info.perfil.id_turista
    );
    if (infoPoints.status === 200) setPoints(infoPoints?.data.puntos);
  };

  const navLinks = [];

  navLinks[ROLES.TOURIST] = [
    { name: t('pacifico'), pathname: '/region-pacifico' },
    { name: t('experiencia'), pathname: '/experiencias' },
    { name: t('events'), pathname: '/eventos' },
    { name: t('shop'), pathname: '/compras' },
  ];

  navLinks[ROLES.NO_LOGGED] = [
    { name: t('pacifico'), pathname: '/region-pacifico' },
    { name: t('experiencia'), pathname: '/experiencias' },
    { name: t('events'), pathname: '/eventos' },
  ];

  useEffect(() => {
    setTouristLogged(false);
    if (user === null || user === undefined) return;
    if (getRole() === ROLES.TOURIST) {
      setProfilePath('cuenta?tab=1');
      setPointsTourist().then();
      setTouristLogged(true);
      fillTheWidthOfTheWindow();
    }

    if (getRole() === ROLES.PST) {
      setProfilePath('/negocios/datos-de-empresa');
    }
  }, [user]);

  navLinks[ROLES.PST] = [
    {
      icon: 'store',
      name: 'Ubicacion',
      pathname: '/negocios/ubicacion',
    },
    {
      icon: 'store',
      name: 'Servicios turísticos',
      pathname: '/negocios/ofertas',
      query: { tipo: 'servicio' },
    },
    {
      icon: 'store',
      name: 'Paquetes turísticos',
      pathname: '/negocios/ofertas',
      query: { tipo: 'paquete' },
    },
    {
      icon: 'notifications',
      name: t('notificaciones'),
      pathname: '/',
    },
  ];

  const dropdownOptionPst = [
    {
      route: `/${profilePath}`,
      label: t('account'),
      icon: 'user-profile',
    },
    {
      route: '/temporadas',
      label: t('seasons'),
      icon: 'calendar',
    },
    {
      route: '/informacion',
      label: t('information'),
      icon: 'info-menu',
    },
    {
      route: '/ayuda',
      label: t('ayuda'),
      icon: 'headset',
    },
  ];

  const dropdownOption = [
    {
      route: `/${profilePath}`,
      label: t('account'),
      icon: 'user-profile',
    },
    {
      route: '/cupones',
      label: t('cupones'),
      icon: '',
      img: '/images/svg/icon-5.svg',
    },
    {
      route: '/mis-compras',
      label: t('compras'),
      icon: 'shopping-bags',
    },
    {
      route: '/temporadas',
      label: t('seasons'),
      icon: 'calendar',
    },
    {
      route: '/informacion',
      label: t('information'),
      icon: 'info-menu',
    },
    {
      route: '/ayuda',
      label: t('ayuda'),
      icon: 'headset',
    },
  ];

  const printNavLinks = () => {
    if (
      windowWidth != undefined &&
      Number(windowWidth) <= windowWidthForMobile
    ) {
      return mobileMenu?.map((navLink, index) => {
        if (index === 4) return null;
        return (
          <li key={index}>
            <Link
              href={{
                pathname: navLink.pathname ?? navLink.route,
                query: navLink.query ?? null,
              }}
            >
              <a href="">
                <p>
                  {navLink.name}
                  {navLink.label}
                </p>
              </a>
            </Link>
          </li>
        );
      });
    }

    return navLinks[pageType ?? ROLES.TOURIST].map((navLink, index) => {
      if (
        (user?.info?.perfil?.id_categoria_PST !== AGENCY_CATEGORY_ID_FOR_PST &&
          navLink.query?.tipo === 'paquete') ||
        (user?.info?.perfil?.id_categoria_PST === AGENCY_CATEGORY_ID_FOR_PST &&
          navLink.query?.tipo === 'servicio')
      ) {
        return null;
      }

      return (
        <li key={index}>
          <Link
            href={{
              pathname: navLink.pathname ?? '/',
              query: navLink.query ?? null,
            }}
          >
            <a href="">
              {navLink.icon && <Icon icon={navLink.icon} size={23} />}
              <p>{navLink.name}</p>
            </a>
          </Link>
        </li>
      );
    });
  };

  useEffect(() => {
    fillTheWidthOfTheWindow();
  }, []);

  return (
    <div className={styles.headerContainer}>
      <div className={styles.mainHeader}>
        <button
          className={styles.openMobileMenu}
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        >
          <Icon icon="menu" size={19} />
        </button>
        <div className={styles.logoContainer}>
          <Link href="/">
            <a href="">
              <Image
                alt="Logo Pacifico Colombia"
                src={'/images/logo-pacifico-colombia.svg'}
                layout="fill"
              />
            </a>
          </Link>
        </div>

        <div className={styles.headerContent}>
          <div>
            <p>{pageType === ROLES.PST && 'Portal de oferta'}</p>

            <div className={styles.options}>
              <LanguageSelection />

              {!user && (
                <div className={styles.registerLogin}>
                  <Link href="/negocios">
                    <a className="btn">{t('registerBusiness')}</a>
                  </Link>

                  <button
                    className="btn primary"
                    onClick={() => setShowLoginPopup(true)}
                  >
                    {t('login')}
                  </button>
                </div>
              )}

              {user && (
                <>
                  {pageType === ROLES.TOURIST && (
                    <button
                      className={styles.openShop}
                      onClick={() => router.push('/mis-compras')}
                    >
                      <Icon icon="shopping-bags" size={24} />
                    </button>
                  )}
                  <NotificationButtonNav />

                  <DropDown
                    name={t('cuenta')}
                    className={styles.profileLink}
                    user={user}
                    type={'profile'}
                  >
                    <DropdownMenu
                      options={
                        pageType === ROLES.PST ||
                        Number(user?.info?.usuario?.id_rol) === ROLES.PST
                          ? dropdownOptionPst
                          : dropdownOption
                      }
                    >
                      {user && (
                        <SignOutButton
                          setShowLogoutPopup={setShowLogoutPopup}
                        />
                      )}
                    </DropdownMenu>
                  </DropDown>
                </>
              )}

              {pageType === ROLES.TOURIST && (
                <button
                  className={styles.openSearchBox}
                  onClick={() => setShowSearchBox(true)}
                >
                  <Icon icon="search" size={24} />
                </button>
              )}
            </div>
          </div>

          <div>
            <ul>{printNavLinks()}</ul>

            {
              <button
                aria-label="Botón de búsqueda de servicios"
                className={styles.searchButton}
                onClick={() => setShowSearchBox(true)}
              >
                {t('search')} <Icon icon="search" size={18} />
              </button>
            }
          </div>
        </div>
      </div>

      <div
        className={classNames(
          mobileMenuStyles.mobileMenu,
          showMobileMenu ? mobileMenuStyles.opened : null
        )}
      >
        <ul>
          {user && (
            <li>
              <Link href={profilePath}>
                <a href="">
                  <Icon icon="user-profile" size={23} />
                  <p>{t('perfil')}</p>
                </a>
              </Link>
            </li>
          )}

          {user === null && (
            <li>
              <button onClick={() => showLoginBoxOnMobile()}>
                {t('login')}
              </button>
            </li>
          )}

          {printNavLinks()}

          {user && (
            <li>
              <button onClick={() => setShowLogoutPopup(true)}>
                <Icon icon="sign-out" size={23} />
                <p>{t('cerrarSesion')}</p>
              </button>
            </li>
          )}
        </ul>
      </div>

      <BigSearchBox
        isOpened={showSearchBox}
        onBigSearchBoxClosed={() => setShowSearchBox(false)}
      />

      {showLoginPopup && (
        <LoginBox handleClose={() => setShowLoginPopup(false)} />
      )}

      {showLogoutPopup && (
        <div className="modalContainer">
          <div className={classNames('modal', styles.logoutPopup)}>
            <p>¿Estás seguro que quieres cerrar sesión?</p>

            <div className={styles.options}>
              <button
                className="btn outline-primary"
                onClick={() => setShowLogoutPopup(false)}
              >
                No
              </button>
              <button className="btn primary" onClick={() => signOutUser()}>
                Sí
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
