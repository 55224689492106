import styles from '../styles/components/login-box.module.scss';
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import {
  forgotPassword,
  forgotPasswordSubmit,
  signInWithEmailAndPassword,
} from '../services/cognito';
import Button from './base/button';
import InputAndLabel from './base/input-and-label';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import NotificationBox from './base/notification-box';
import { emailIsValid } from '../shared/utils';
import { apiRestGet, apiRestPost } from '../services/request';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';

import PasswordStrengthBox from './password-strength-box';

import classNames from 'classnames';

export default function LoginBox(props) {
  const [loginError, setLoginError] = useState(false);
  const [loginBloq, setLoginBloq] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [register, setRegister] = useState(false);
  const [showRecoverPassword, setShowRecoverPassword] = useState(false);
  const [emailForPasswordRecovery, setEmailForPasswordRecovery] =
    useState(null);
  const [recoveryEmailSent, setRecoveryEmailSent] = useState(false);
  const [codeForRecovery, setCodeForRecovery] = useState('');
  const [newPassword, setNewPassword] = useState(null);
  const [newPasswordIsStrong, setNewPasswordIsStrong] = useState(false);
  const [passwordHasBeenChanged, setPasswordHasBeenChanged] = useState(false);
  const [codeErrorMessage, setCodeErrorMessage] = useState(null);
  const [emailCheckErrorMessage, setEmailCheckErrorMessage] = useState('');
  const [errorRole, setErrorRole] = useState(false);
  const router = useRouter();
  const handleClose = () => {
    props.handleClose();
  };
  const t = useTranslations('LoginBox');

  const toggleRegister = () => {
    setRegister(!register);
  };

  useEffect(() => {
    document.querySelector('body').style.overflow = 'hidden';
    return () => {
      document.querySelector('body').style.overflow = 'visible';
    };
  }, []);

  const qrRedeem = async (email) => {
    let str = sessionStorage.getItem('pointsTourist');
    if (str) {
      str = str.trim().replaceAll(' ', '+');
      await apiRestPost(
        process.env.NEXT_PUBLIC_AUTH_CONFIG_SERVICE_URL +
          'puntos/registerQRCodeTemporal',
        {
          data: str,
          correo_electronico: email,
        }
      );
      sessionStorage.removeItem('pointsTourist');
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorRole(false);
    setLoginError(false);
    setLoadingLogin(true);
    setLoginBloq(false);
    let state = await apiRestGet(
      process.env.NEXT_PUBLIC_LOGIN_SIG_SERVICE_URL +
        'state?correo_electronico=' +
        email
    );
    if (state?.status === 200) {
      if (state?.data?.state === 4) {
        setLoginBloq(true);
        return;
      }
      if (state?.data?.id_rol === 1 || state?.data?.id_rol === 2) {
        signInWithEmailAndPassword(email, password)
          .then(async (result) => {
            setLoadingLogin(false);
            if (result.code === 'NotAuthorizedException') {
              setLoginError(true);
            } else {
              let log = await apiRestPost(
                process.env.NEXT_PUBLIC_LOGIN_SIG_SERVICE_URL + 'logAccess',
                { correo_electronico: email, id_aplicacion: 2 }
              );
              qrRedeem(email);
              state?.data?.id_rol === 1
                ? router.push('/')
                : router.push('/negocios');
              handleClose();
            }
          })
          .catch((error) => {
            console.log(error);
            setLoadingLogin(false);
          });
      }
    }
  };

  const toggleRecoverPasswordBox = (event) => {
    event.preventDefault();
    setShowRecoverPassword(!showRecoverPassword);
  };

  const sendRecoveryEmail = async () => {
    setEmailCheckErrorMessage('');
    const emailCheckResult = await apiRestGet('user/email', {
      correo_electronico: emailForPasswordRecovery,
    });

    if (emailCheckResult.data !== null) {
      await forgotPassword(emailForPasswordRecovery);
      setRecoveryEmailSent(true);
    } else {
      setEmailCheckErrorMessage(t('emailNotRegistered'));
    }
  };

  const goBackToLogin = () => {
    setEmailForPasswordRecovery(null);
    setRecoveryEmailSent(false);
    setShowRecoverPassword(false);
    setPasswordHasBeenChanged(false);
  };

  const validateNewPassword = (newPasswordValue: boolean) => {
    if (newPasswordValue) {
      setNewPasswordIsStrong(newPasswordValue);
    }
  };

  const changePassword = async () => {
    setCodeErrorMessage(null);

    const passwordChangeResult = await forgotPasswordSubmit(
      emailForPasswordRecovery,
      codeForRecovery,
      newPassword
    );

    if (passwordChangeResult === 'SUCCESS') {
      setPasswordHasBeenChanged(true);
    }

    if (passwordChangeResult?.code === 'CodeMismatchException') {
      setCodeErrorMessage(t('codeNotWell'));
    }

    if (passwordChangeResult?.code === 'ExpiredCodeException') {
      setCodeErrorMessage(t('codeExpired'));
    }
  };

  const socialSignIn = async (event, provider: any) => {
    event.preventDefault();

    await Auth.federatedSignIn({
      provider: provider,
    });
  };

  return (
    <div className={styles.loginBoxComponent}>
      <div className={styles.box}>
        <div className={styles.logo}>
          <Image
            alt="Logo Pacifico Colombia"
            src="/images/logo-pacifico-colombia.svg"
            layout="fill"
            objectFit="contain"
            loading="lazy"
          />
        </div>
        <Button
          className={styles.iconOnly + ' iconOnly'}
          icon="close"
          onClick={handleClose}
        />
        <div className={styles.registerAndLogin} hidden={showRecoverPassword}>
          <h1>{register ? t('signUp') : t('login')}</h1>
          {register ? (
            <div className={styles.options}>
              <Link href="/completa-tu-perfil">
                <a className={styles.btn + ' btn greyWithIcon'}>
                  <Image
                    alt="Logo correo"
                    src="/icons/mail.svg"
                    width="24px"
                    height="16.8px"
                    loading="lazy"
                  />
                  <span>{t('continueEmail')}</span>
                </a>
              </Link>
              <span className={styles.division}>O</span>
              <Button
                onClick={(e) =>
                  socialSignIn(e, CognitoHostedUIIdentityProvider.Google)
                }
                className={styles.btn + ' greyWithIcon'}
                icon="google"
              >
                <span>{t('continueGoogle')}</span>
              </Button>
              <p>
                <Button className="textButton" onClick={toggleRegister}>
                  {t('haveAccount')}
                </Button>
              </p>
            </div>
          ) : (
            <form className={styles.emailForm}>
              <div className={styles.formContent}>
                <InputAndLabel
                  className={styles.inputContainer}
                  onChange={(e) => setEmail(e.target.value)}
                  label={t('email')}
                  name="email"
                  type="email"
                  placeholder="correo@ejemplo.com"
                />
                <InputAndLabel
                  className={styles.inputContainer}
                  onChange={(e) => setPassword(e.target.value)}
                  label={t('password')}
                  name="pwd"
                  type="password"
                  placeholder="********"
                />
                <button
                  className={styles.forgotPasswordButton}
                  onClick={(e) => toggleRecoverPasswordBox(e)}
                >
                  {t('forgotPassword')}
                </button>

                {loginError && (
                  <p className={styles.errorMessage}>
                    {t('userPasswordWrong')}
                  </p>
                )}

                {loginBloq && (
                  <p className={styles.errorMessage}>{t('userBlock')}</p>
                )}
                {errorRole && (
                  <p className={styles.errorMessage}>{t('userNotTurist')}</p>
                )}

                <Button
                  disabled={loadingLogin}
                  className={styles.loginButton + ' primary'}
                  onClick={handleSubmit}
                >
                  {t('enter')}
                </Button>
                <p>
                  {t('login')} {t('with')} {''}
                  <Button
                    onClick={(e) =>
                      socialSignIn(e, CognitoHostedUIIdentityProvider.Google)
                    }
                    className="textButton"
                  >
                    Google
                  </Button>
                </p>
              </div>
              <p>
                {t('dontHaveAccount')}{' '}
                <Button className="textButton" onClick={toggleRegister}>
                  {t('goSignUp')}
                </Button>
              </p>
            </form>
          )}
        </div>
        <div className={styles.recoverPassword} hidden={!showRecoverPassword}>
          <h4>{t('recoverPassword')}</h4>

          {!recoveryEmailSent && (
            <div className={styles.emailForPasswordRecoveryForm}>
              <p>{t('descriptionRecoverPassword')}</p>
              <InputAndLabel
                className={styles.emailField}
                onChange={(e) => setEmailForPasswordRecovery(e.target.value)}
                label={t('email')}
                name="emailForPasswordRecovery"
                type="email"
                placeholder="correo@ejemplo.com"
                errorMessage={emailCheckErrorMessage}
              />
              <div className={styles.buttons}>
                <button
                  className={classNames('btn primary', styles.buttonNext)}
                  onClick={() => sendRecoveryEmail()}
                  disabled={!emailIsValid(emailForPasswordRecovery)}
                >
                  {t('recoverPassword')}
                </button>
                <button onClick={(e) => toggleRecoverPasswordBox(e)}>
                  {t('back')}
                </button>
              </div>
            </div>
          )}

          {recoveryEmailSent && (
            <div className={styles.emailSent}>
              <p>{t('descriptionEmailCode')}</p>
              <InputAndLabel
                className={styles.inputContainer}
                name="code"
                label={t('code')}
                type="text"
                placeholder="12345"
                onChange={(e) => setCodeForRecovery(e.target.value)}
                errorMessage={codeErrorMessage}
              />
              <InputAndLabel
                className={styles.inputContainer}
                name="new_password"
                label={t('newPassword')}
                type="password"
                placeholder="********"
                onChange={(e) => setNewPassword(e.target.value)}
              />
              {newPassword !== '' && (
                <PasswordStrengthBox
                  password={newPassword}
                  onPasswordValidation={(passwordIsStrong) =>
                    validateNewPassword(passwordIsStrong)
                  }
                />
              )}

              {!passwordHasBeenChanged && (
                <button
                  className={classNames('btn primary', styles.buttonNext)}
                  disabled={codeForRecovery === '' || !newPasswordIsStrong}
                  onClick={changePassword}
                >
                  {t('changePassword')}
                </button>
              )}

              {passwordHasBeenChanged && (
                <div className={styles.passwordChangedMessage}>
                  <NotificationBox text={t('descriptionChangePassword')} />
                  <button className="btn" onClick={goBackToLogin}>
                    {t('back')}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
